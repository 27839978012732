<template>
  <div class="author_center" center_bgc>
    <div class="top_header">
      <span class="tab_item" v-for="(item,index) in tab_list" :key="index" @click="go_path(item)" :class="[menu_num===item.id&&item.id!==1?'choose_it':'',menu_num===item.id&&item.id===1?'choose_itx':'']">{{item.title}}</span>
      <span class="tab_block"></span>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name:'author_center',
  components:{
  },
  data(){
    return{
      menu_num:1,
      tab_list:[
        {title:'作品管理',path:'/works_manage',id:1},
        {title:'稿酬收入',path:'/works_income',id:2},
        {title:'考勤管理',path:'/check_on',id:3},
      ]
    }
  },
  methods:{
    // 刷新
    bush_page(){
      this.$forceUpdate()
    },
    // 界面跳转
    go_path(item){
      this.menu_num=item.id
      this.$router.push({
        path:`${item.path}`
      })
    },

  }
}
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.author_center{
  // border: 1px solid #e4e4e4;
  // box-shadow: 0 0 5px #e4e4e4;
  border-radius: 5px;
  padding-bottom: 15px;
  .top_header{
    width: 100%;
    background: #f5f7fa !important;
    // border: 1px solid #e4e7ed;
    border-bottom: 0px solid transparent;
    @include flex-row();
    .tab_item{
      padding:10px 15px;
      line-height: 20px;
      color: #909399;
      // border-bottom:1px solid #e4e7ed;
      cursor: pointer;
      &:hover{
        color: #409eff;
      }
    }
    .tab_block{
      flex: 1;
      height: 41px;
      // border-bottom:1px solid #e4e7ed;
    }
  }
  .content{
    padding: 15px;
    // border:1px solid #e4e7ed;
    // border-top:1px solid transparent
  }
}
.choose_it{
  color: #409eff;
  background: #ffffff;
  border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  border-bottom-color:#ffffff !important;
}
.choose_itx{
  color: #409eff;
  background: #ffffff;
  border-right: 1px solid #e4e7ed;
  border-bottom-color:#ffffff  !important;
}

</style>


